
//@ts-nocheck
import Vue from "vue";
import MydocMenu from "@/views/mydoc/menu.vue";
import MenuSimple from "@/views/mydoc/menuSimple.vue";
import FileList from "@/views/recycle/fileList.vue";
import TileList from "@/views/mydoc/tileList.vue";
import PreviewImg from "@/components/previewImg/index.vue";
import CooperationModal from "@/views/mydoc/CooperationModal.vue";
import ShareModal from "@/views/mydoc/ShareModal.vue";
import FileDetails from "@/components/FileDetails/index.vue";
import { Radio } from "ant-design-vue";
import Popup from "@/views/popup/index.vue";
// 内容较多, 数据暂存混入文件, 此文件只写模板
import Mixins from "@/views/mydoc/mixin/index";

const columns = [
  {
    title: "名称",
    dataIndex: "userFileName",
    align: "left",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "来自",
    dataIndex: "sourceName",
    align: "center",
  },
  {
    title: "修改时间",
    dataIndex: "updateTime",
    align: "center",
    scopedSlots: { customRender: "updateTime" },
  },
  // {
  //   title: "备注",
  //   dataIndex: "remark",
  //   align: "center",
  //   scopedSlots: { customRender: "comment" },
  // },
  {
    title: "文件大小",
    dataIndex: "userFileSize",
    align: "center",
    scopedSlots: { customRender: "size" },
  },
];

export default Vue.extend({
  name: "TagFile",
  mixins: [Mixins],
  components: {
    [Radio.name]: Radio,
    MydocMenu,
    MenuSimple,
    FileList,
    TileList,
    Popup,
    PreviewImg,
    CooperationModal,
    ShareModal,
    FileDetails,
  },
  props: {},
  data() {
    return {
      columns,
    };
  },
  watch: {},
  computed: {
    menuType() {
      if (this.contextmenuValue.sourceName === "个人文档") {
        return this.checkIdList.length > 1 ? "normal" : "full";
      } else {
        return "teamFull";
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 页面初始化
    ViewInit() {
      (this as any).id = "objId";
    },
    fetchData() {
      this.wrapLoading = true;
      let params = {
        id: this.$route.query.tagId,
        companyId: this.$store.state.user.companyId,
      };
      return this.$apis
        .documentTagFiles(params)
        .then((res: any) => {
          (this as any).setData({ list: res.data.list });
          return Promise.resolve();
        })
        .finally(() => {
          this.wrapLoading = false;
        });
    },
    toCheckItem(el: any) {
      if (el[this.id]) {
        this.moduleType = el.moduleType;
        // console.log(el.moduleType);
        let arr: Array<string | number> = this.checkIdList;
        if (arr.includes(el[this.id]) && arr.length === 1) {
          arr.splice(
            arr.findIndex((item: any) => item === el[this.id]),
            1
          );
        } else {
          this.checkIdList = [el[this.id]];
        }
      }
    },
    // 右键点击
    handleContextMenu(e, el) {

      console.log('右键点击',el);
      

      this.moduleType = el.moduleType;
      // console.log(this.moduleType);
      this.$nextTick(() => {
        this.onContextMenu(e, "fileContextContent", el, "single");
      });
    },
  },
});
